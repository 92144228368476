<template>
  <section class="section">
    <swiper
      :modules="modules"
      navigation
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <swiper-slide v-for="(image, index) in images" v-bind:key="index">
        <img
          class="swiper__image"
          v-bind:src="image.url"
          alt="{{ image.title }}"
        />
        <div v-if="image.title" class="caption">{{ image.title }}</div>
      </swiper-slide>
    </swiper>
  </section>
</template>
<script>
import { Navigation } from "swiper";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper style
import "swiper/css";
import "swiper/css/navigation";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation],
    };
  },

  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 50,
        loop: true,
      },
      images: [
        {
          url: require("@/assets/carousel-1.jpg"),
          title: "Babylights",
        },
        {
          url: require("@/assets/carousel-2.jpg"),
        },
        {
          url: require("@/assets/carousel-3.jpg"),
        },
        {
          url: require("@/assets/carousel-4.jpg"),
        },
        {
          url: require("@/assets/carousel-5.jpg"),
        },
        {
          url: require("@/assets/carousel-6.jpg"),
        },
        {
          url: require("@/assets/carousel-7.jpg"),
        },
        {
          url: require("@/assets/carousel-8.jpg"),
        },
        {
          url: require("@/assets/carousel-9.jpg"),
        },
        {
          url: require("@/assets/carousel-10.jpg"),
        },
      ],
    };
  },
};
</script>
